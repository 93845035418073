@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --background: #c9dfec;
  --color1: #3a4a58;
  --color2: #5a9fd6;
  --color3: #f28b30;
  --color4: #f3f6f9;
  --color5: #a8bfcf;
}

.App {
  text-align: center;
  font-family: "Nunito", sans-serif;
}

body {
  background-color: var(--background);
}

.Logo {
  font-family: 'Nunito', sans-serif;
  font-size: 5rem; 
  font-weight: 800; 
  color: #0056b3; 
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
}

.SmallLogo {
  font-size: 3rem; 
  font-weight: 800; 
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
}

h1, h2, h3{
  color: var(--color1) !important
}

.MainPageContainer {
  height: 89vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.MainPageCardContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem
}

.LandingImage {
  width: 50rem;
}

td {
  vertical-align: middle;
  text-align: center;   
}

.Header {
  background-color: var(--color5);
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 20px;
  align-items: center;
}

.SubjectContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0
}


.SubjectName {
  border: solid transparent 1px;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SubjectLogo, .TeacherCards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 1rem;
}

.TeacherCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  
  border-top: solid var(--color5) 0.5px;
  width: 100%;
}

.NameContainer{
  width: 10rem;
  border-right: solid var(--color5) 0.5px;
  display: flex;
  align-self: stretch;
  align-items: center;
  min-height: 4rem;
}
.AverageContainer{
  width: 4rem;
  border-right: solid var(--color5) 0.5px;
  display: flex;
  align-self: stretch;
  align-items: center;
  min-height: 4rem;
}

.Average{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
}

.ButtonContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.SubjectsContainer {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 2rem;
}

.Subjects, .TeacherSubjects {
  margin: 2rem;
  padding: 1rem;
  min-height: 50vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  background-color: var(--color4);
}

.PrincipalSubjects, .StudentsList, .PrincipalSchedule{
  margin: 1rem;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  background-color: var(--color4);
}

.SmallHeader {
  margin: 1rem 0 2rem;
  border-bottom: solid var(--color5) 1px
}


.StudentSubjectCard{
  min-width: 18rem;
  max-width: 30rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  margin: 0 5px
}

.UserCard {
  background-color: var(--color4);
  border-radius: 15px;
  max-width: 40rem;
  margin: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  text-decoration: none;
}

.UserCard:hover {
  transform: translateY(-5px);
}

.UserCard:hover .Icon{
  transform: scale(1.1);
  transform: translateX(5px);
}

.UserCardSections {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem
}

.RoleIcon {
  color: gray;
  flex-shrink: 0;
  transition: color 0.3s ease;
}

.UserCard:hover .RoleIcon {
  color: var(--color3);
}

.UserCardText {
  color: var(--color1);
  margin-top: 15px
}

.UserCardSections button {
  border: none;
  background-color: var(--color2);
  border-radius: 0 15px 15px 0;
}

.Accordion {
  margin: 1rem;
  padding: 10px;
  border-radius: none;
}

.AccordionItem {
  margin: 1rem 0;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 20px; 
  border-bottom-right-radius: 20px;
}

.accordion-item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

.TeacherCardItem .card-header, .TeacherCardItem .card-body{
  padding: 5px 0;
  margin: 0;
  height: 3rem;
}

.TeacherCardItem {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.EditGrade button {
  border: none;
  border-radius: 5px;
  padding: 2px 0;
}

.NewGradeHeader, .ScheduleHeader {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0
}

.ModalGradesInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: solid var(--color5) 1px
}

.PrincipalPageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.PrincipalPageItem1 {
  flex-grow: 1;
  min-width: 22rem;
}
.PrincipalPageItem2 {
  min-width: 22rem;
  flex-grow: 1;
}

.SmallerSubjectName {
  border: solid transparent 1px;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin: 0;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SmallerSubjectIcon {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.PrincipalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0; 
  padding: 10px;
  border-bottom: solid var(--color5) 1px
}

@media screen and (max-width: 1475px) {
  .LandingImage {
    display: none;
  }
  .Subjects, .TeacherSubjects {
    margin: 1rem;
    padding: 0.5rem;
  }
  .SubjectsContainer {
    gap: 1rem;
    margin-bottom: 1rem;
  }
}



@media screen and (max-width: 1300px) {
  .PrincipalPageContent {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1000px) {
  .LandingImage {
    display: none;
  }
  .Subjects, .TeacherSubjects {
    margin: 0.5rem;
    padding: 0.2rem;
  }
  .Accordion {
    margin: 0rem;
    padding: 1px;
  }
  .AccordionItem {
    margin: 0.2rem;
    border-radius: 0;
    padding: 0;
    box-shadow: none
  }
  .NameContainer{
    width: 5rem;
  }
  .AverageContainer{
    width: 2rem;
    border-right: none;
  }
  .TeacherCards{
    gap: 1px;
    flex-wrap: wrap;
    padding: 5px;
  }
  .TeacherCardItem .card-body{
    height: 2rem;
  }
  .PrincipalSubjects, .StudentsList, .PrincipalSchedule{
    margin: 0.2rem;
    padding: 0.3rem;
    box-shadow: none;
    border-radius: none;
  }

}


@media screen and (max-width: 600px) {
  .UserCardText p{
    display: none;
  }
  .UserCardSections {
    align-items: center;
    margin: 10px 0 10px
  }
  .UserCardSections button {
    align-self: stretch;
  }
  .Subjects, .TeacherSubjects {
    margin: 0;
    padding: 0.2rem;
    border-radius: 0;
  }
}

